import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Container, Box } from '@mui/material';

import { APPBAR_MAX_HEIGHT } from 'config/constants';

function Body({ children }: React.PropsWithChildren<{}>) {
	const { classes } = useStyles();

	return (
		<Container component="main" className={classes.container}>
			<Box className={classes.content}>{children}</Box>
		</Container>
	);
}

const useStyles = makeStyles()((theme) => ({
	container: {
		width: '100%',
		maxWidth: '100%',
		padding: theme.spacing(0),
		paddingTop: `${APPBAR_MAX_HEIGHT + theme.spacing(2)}px`,
	},
	content: {
		maxWidth: '100%',
		padding: theme.spacing(0, 1),
	},
}));

export default Body;
