const users = {
	deleteTitle: 'User deletion',
	deleteText: 'Delete selected user?',
	deleteText_plural: 'Delete selected users?',
	createUser: 'Create user',
	creationSuccessTitle: 'User created',
	creationSuccessDescription:
		'The "{{first_name}} {{last_name}}" user was successfully created, now {{first_name}} can access the panel using the username "{{username}}" and a password of his choice.',
};

export default users;
