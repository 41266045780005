const users = {
	deleteTitle: 'Eliminación de usuarios',
	deleteText: '¿Está seguro que desea eliminar al usuario seleccionado?',
	deleteText_plural: '¿Está seguro que desea eliminar a los usuarios seleccionados?',
	createUser: 'Crear usuario',
	creationSuccessTitle: 'Usuario creado',
	creationSuccessDescription:
		'El usuario de "{{first_name}} {{last_name}}" fué creado con éxito, ahora "{{first_name}}" puede acceder al panel utilizando el nombre de usuario "{{username}}" y una contraseña de su elección.',
};

export default users;
