import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import useUser from 'hooks/useUser';

import ScreenContainer from 'components/containers/ScreenContainer';
import PaperContainer from 'components/containers/PaperContainer';

import ClientEditionForm from 'components/ClientEditionForm';
import { CircularProgress } from '@mui/material';

function ClientEdition() {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const { id } = useParams<{ id: string }>();

	const userData = useUser();

	const title = userData.loading ? t('common:loading') : t('common:clientTitle', { name: userData.user.email });

	if (!id) {
		navigate('/clients');
	}

	return (
		<ScreenContainer title={title}>
			<PaperContainer>
				{!!id && <ClientEditionForm id={id} />}
				{!id && <CircularProgress />}
			</PaperContainer>
		</ScreenContainer>
	);
}

export default ClientEdition;
