const common = {
	// Common
	username: 'Username',
	password: 'Password',
	firstName: 'First name',
	lastName: 'Last name',
	email: 'Email',
	accept: 'Accept',
	cancel: 'Cancel',
	close: 'Close',
	create: 'Create',
	delete: 'Delete',
	edit: 'Edit',
	filter: 'Filter',
	general: 'General',
	reload: 'Reload',
	save: 'Save',
	search: 'Search',
	selected: 'selected',
	upload: 'Upload',
	success: 'Saved',
	download: 'Download',
	densePadding: 'Dense row',
	rowsPerPage: 'Rows per page',
	findUser: 'Find user ...',
	import: 'Import',
	importingPleaseWait: 'Please wait, importing...',
	more: 'More',
	code: 'Code',
	loading: 'Loading, please wait...',
	role: 'Role',
	role_admin: 'Administrator',
	role_panel: 'Panel user',
	role_user: 'Client',
	active: 'Active',
	inactive: 'Inactive',
	selectFile: 'Select file',
	fileError: 'File format not supported',
	importSuccess: 'File imported successfully',
	language: 'language',
	spanish: 'Spanish',
	english: 'English',
	valueTo: 'Up to',
	$lte: 'Less than or equal to',
	$gte: 'Greater than or equal to',
	backOfficeDocumentationTitle: 'Panel documentation',
	backOfficeDocumentationDescription: 'Panel usage documentation link',
	itDocumentationTitle: 'IT documentation',
	itDocumentationDescription: 'Server configuration documentation link',
	welcome:
		'Welcome {{name}}, if you need help with the panel or some server configuration you can use the links below.',
	export: 'Export',
	tokenExpired: 'Your session has expired, please login again',
	serverIsDown: 'The API server is down, please contact IT team for more info',
	followingElementsWithError:
		'The following elements with column "{{key}}" ({{elements}}) could not be created or updated, error: {{error}}',
	status: 'Status',
	deleteText: 'Are you sure you want to delete the selected element?',
	cancelDescription: "Are you sure you wan't to return to login screen?",
	exampleFile: 'Download an example .csv file',
	account: 'account',
	account_plural: 'accounts',
	dashboard: 'dashboard',
	favorite: 'favorite',
	favorite_plural: 'favorites',
	inbox: 'inbox',
	personal_area: 'My section',
	profile: 'profile',
	user: 'user',
	user_plural: 'users',
	user_me: 'my data',
	userTitle: '{{name}}',
	resetPassword: 'Clear password',
	resetPasswordTitle: 'Clear password',
	resetPasswordText: 'Clear password of selected user?',
	creationPasswordText:
		'The first time the user logs in it will create his password which will be used in subsequent accesses.',
	clients_plural: 'Clients',
	loginDate: 'Last login',
} as const;

export default common;
