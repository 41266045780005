import { useState, useEffect } from 'react';
import i18n from 'i18next';
import { initReactI18next, I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import store from 'store';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MomentUtils from '@date-io/moment';

import resources, { ns } from 'config/i18n';
import Router from 'components/Router';
import LoadingLayout from 'components/layouts/LoadingLayout';
import Language from './Language';
import ServerDown from 'components/ServerDown';

/**
 * This component is used to configure the app before it's rendered
 */
const Config = () => {
	const [loading, setLoading] = useState(true);
	// This will run on component update or unmount (does not run on first mount)
	const cleanUp = () => {
		//console.debug('[Config, cleanUp] cleaning up component');
	};

	useEffect(() => {
		// [Insert effects before app renders here]

		const configuration = {
			resources,
			ns,
			debug: false,
			lng: 'es',
			fallbackLng: 'es',
			react: {
				useSuspense: false,
			},
			interpolation: {
				escapeValue: false,
			},
		};

		i18n
			.use(initReactI18next) // passes i18n down to react-i18next
			.init(configuration, () => {
				// Once apps ready set loading boolean in true
				setLoading(false);

				// Emulate loading time (comment setLoading line and uncomment next line)
				//setTimeout(() => setLoading(false), 1000);
			});

		return cleanUp();
	}, []);

	return loading ? (
		<LoadingLayout />
	) : (
		<I18nextProvider i18n={i18n}>
			<LocalizationProvider dateAdapter={MomentUtils}>
				<Provider store={store}>
					<Language>
						<ServerDown>
							<Router />
						</ServerDown>
					</Language>
				</Provider>
			</LocalizationProvider>
		</I18nextProvider>
	);
};

export default Config;
