import { Outlet } from 'react-router-dom';

import useIsLogged from 'hooks/useIsLogged';

import Login from 'components/screens/system/Login';

function Logged({ element: Element }: BaseRoutesProps) {
	const logged = useIsLogged();

	if (!logged) {
		return <Login />;
	}

	return Element ? <Element /> : <Outlet />;
}

export default Logged;
