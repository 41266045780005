import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { Divider, ListSubheader, List } from '@mui/material';

import { DRAWER_WIDTH } from 'config/constants';

import DashboardItem from './MenuItems/Dashboard';

import UserItem from './MenuItems/User';
import UsersItem from './MenuItems/Users';
import ClientsItem from './MenuItems/Clients';

import AuthorizedComponent from 'components/AuthorizedComponent';

import useInDev from 'hooks/useInDev';

import useDrawer from '../hooks/useDrawer';
import useDrawerActions from '../hooks/useDrawerActions';

function Menu() {
	const inDEV = useInDev();

	const { classes } = useStyles();
	const { t } = useTranslation();

	const { selectedItem } = useDrawer();

	const { setSelectedItem } = useDrawerActions();

	const handleListItemClick = (_event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
		setSelectedItem(index);
	};

	return (
		<List
			component="nav"
			aria-labelledby="nested-list-subheader"
			subheader={
				<ListSubheader component="div" id="nested-list-subheader">
					{t('common:general')}
				</ListSubheader>
			}
			className={classes.root}
		>
			<AuthorizedComponent
				element={() => <DashboardItem selected={selectedItem} onSelect={handleListItemClick} />}
				rule="*"
			/>

			<AuthorizedComponent
				element={() => <UsersItem selected={selectedItem} onSelect={handleListItemClick} />}
				rule="UserFull"
			/>

			<AuthorizedComponent
				element={() => <ClientsItem selected={selectedItem} onSelect={handleListItemClick} />}
				rules={['UserReadOnly', 'UserFull']}
				mode="or"
			/>

			<Divider />

			{inDEV && (
				<ListSubheader component="div" id="nested-list-subheader">
					{t('common:personal_area')}
				</ListSubheader>
			)}

			<AuthorizedComponent
				element={() => <UserItem selected={selectedItem} onSelect={handleListItemClick} />}
				rule={['Profile']}
				mode="or"
			/>
		</List>
	);
}

const useStyles = makeStyles()((theme) => ({
	root: {
		width: '100%',
		maxWidth: DRAWER_WIDTH,
		backgroundColor: theme.palette.background.paper,
		paddingTop: theme.spacing(4),
	},
}));

export default Menu;
