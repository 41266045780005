import { useTranslation } from 'react-i18next';

import { Add as Icon } from '@mui/icons-material';

import FloatIcon from 'components/icons/FloatIcon';

function CreateIcon({ onCreate }: { onCreate: () => any }) {
	const { t } = useTranslation();

	return <FloatIcon onClick={() => onCreate()} title={t('common:create')} icon={Icon} />;
}

export default CreateIcon;
