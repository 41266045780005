import React from 'react';

import { validateComponent } from 'lib/session';
import { getDataFromString, isServerRules } from 'lib/helpers';

import useLoggedAndRules from 'hooks/useLoggedWithRules';

const validRules = isServerRules();

type Props = AuthorizedComponentProps;

const AuthorizedComponent: React.FunctionComponent<Props> = ({ element: Component, mode, children, ...rest }) => {
	const { logged, rulesString } = useLoggedAndRules();
	const rules = getDataFromString<ServerRules>(rulesString, validRules);
	if (logged && validateComponent(rules, { ...rest }, mode)) {
		return Component ? <Component {...rest} /> : children ? <>{children}</> : null;
	}
	return null;
};

export default AuthorizedComponent;
