import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { Typography } from '@mui/material';

type Props = {
	amount: number;
};

const DeletionTypography = ({ amount }: Props) => {
	const { t } = useTranslation();
	const { classes } = useStyles();

	return (
		<Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
			{amount} {t('common:selected')}
		</Typography>
	);
};

const useStyles = makeStyles()((theme) => ({
	title: {
		flex: '1 1 100%',
	},
}));

export default DeletionTypography;
