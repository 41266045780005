import clsx from 'clsx';

import { makeStyles } from 'tss-react/mui';
import { useTheme, Drawer, Divider, IconButton, useMediaQuery, Box, PortalProps } from '@mui/material';
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import { DRAWER_WIDTH } from 'config/constants';

import MenuList from './Menu';
import logo from 'assets/images/logo.jpeg';

type NavigatorProps = {
	container?: PortalProps['container'];
	open: boolean;
	selected: number;
	onClose(): void;
};

function Navigator({ container, open, selected, onClose }: NavigatorProps) {
	const { classes } = useStyles();
	const theme = useTheme();

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<Drawer
			variant={isMobile ? 'temporary' : 'permanent'}
			anchor="left"
			className={clsx(
				classes.drawer,
				!isMobile && {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				}
			)}
			classes={{
				paper: clsx(
					!isMobile && {
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					}
				),
			}}
			container={container}
			open={open}
			onClose={onClose}
		>
			<Box className={classes.header}>
				<img src={logo} alt="Boostock" height={50} />
				<IconButton onClick={onClose}>
					{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
				</IconButton>
			</Box>
			<Divider />
			<MenuList />
		</Drawer>
	);
}

const useStyles = makeStyles()((theme) => ({
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		// ...theme.mixins.toolbar,
	},
	drawer: {
		width: DRAWER_WIDTH,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		textTransform: 'capitalize',
	},
	drawerOpen: {
		width: DRAWER_WIDTH,
		zIndex: theme.zIndex.drawer + 3,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing(9) + 1,
	},
}));

export default Navigator;
