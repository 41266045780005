import { Route, BrowserRouter, Routes } from 'react-router-dom';

import Dashboard from 'components/screens/Dashboard';
import Inbox from 'components/screens/inbox/Inbox';
import Users from 'components/screens/Users';
import UsersEdition from 'components/screens/UserEdition';
import Clients from 'components/screens/Clients';
import ClientEdition from 'components/screens/ClientEdition';

import Login from 'components/screens/system/Login';
import NoMatch from 'components/screens/system/NoMatch';

import Auth from './Auth';

import useLogoutOnTokenExpired from './hooks/useLogoutOnTokenExpired';
import Logged from './Logged';

function Router() {
	// If token has expired, logout user
	useLogoutOnTokenExpired();

	return (
		<BrowserRouter>
			<Routes>
				<Route key="auth" element={<Logged />}>
					{/* PRIVATE ROUTES */}
					<Route key="route-me" path="/me" element={<Auth element={UsersEdition} rules={['Profile']} />} />
					<Route key="route-users-edit" path="/users/:id" element={<Auth element={UsersEdition} rule="UserFull" />} />
					<Route key="route-users" path="/users" element={<Auth element={Users} rule="UserFull" />} />
					<Route
						key="route-clients-edit"
						path="/clients/:id"
						element={<Auth element={ClientEdition} rule="UserFull" />}
					/>
					<Route
						key="route-clients"
						path="/clients"
						element={<Auth element={Clients} rules={['UserReadOnly', 'UserFull']} mode="or" />}
					/>
					{/* SYSTEM PRIVATE ROUTES */}
					<Route key="route-dashboard" path="/dashboard" element={<Auth element={Dashboard} />} />
					<Route key="route-inbox" path="/inbox" element={<Auth element={Inbox} />} />
					<Route key="route-home" path="/" element={<Auth element={Dashboard} />} />
				</Route>
				{/* SYSTEM PUBLIC ROUTES */}
				<Route key="route-login" path="/login" element={<Login />} />
				{/* NoMatch */}
				<Route element={<NoMatch />} />
			</Routes>
		</BrowserRouter>
	);
}

export default Router;
