import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Paper } from '@mui/material';

function PaperContainer({ children, className }: React.PropsWithChildren<{ className?: string }>) {
	const { classes } = useStyles();

	return (
		<Paper elevation={1} className={className || classes.paper}>
			{children}
		</Paper>
	);
}

export default PaperContainer;

const useStyles = makeStyles()((theme) => ({
	paper: {
		padding: theme.spacing(2),
	},
}));
