import common from './common';
import system from './system';
import users from './users';

const es = {
	common,
	system,
	users,
} as const;

export default es;
