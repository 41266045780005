import { makeStyles } from 'tss-react/mui';
import { Menu } from '@mui/material';

// import AccountItem from './ProfileItems/Account';
import LogoutItem from './ProfileItems/Logout';

import useLogout from 'hooks/useLogout';

type ProfileMenuProps = {
	open: boolean;
	anchor: Element | ((element: Element) => Element) | null | undefined;
	onClose: ((event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void) | undefined;
};

function ProfileMenu({ open, anchor, onClose }: ProfileMenuProps) {
	const { classes } = useStyles();

	const logout = useLogout();

	return (
		<Menu
			id="menu-appbar"
			className={classes.menu}
			anchorEl={anchor}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			keepMounted
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={open}
			onClose={onClose}
		>
			{/* <AccountItem onClick={onClick} /> */}
			<LogoutItem onClick={logout} />
		</Menu>
	);
}

const useStyles = makeStyles()((theme) => ({
	menu: {
		textTransform: 'capitalize',
	},
}));

export default ProfileMenu;
