import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import {
	LinearProgress,
	Typography,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';

type DescriptionProps = {
	title: string;
	confirmButton?: string;
	cancelButton?: string;
	hideCancel?: boolean;
	description: string;
	onClose: (confirmed: boolean) => void;
	loading?: boolean;
	loadingTitle?: string;
	warningMessage?: string;
};

type ChildrenProps = PropsWithChildren<Omit<DescriptionProps, 'description'> & { description?: string }>;

export type ConfirmationBasePropsType = DescriptionProps | ChildrenProps;

export default function ConfirmationDialog(props: ConfirmationBasePropsType) {
	const { t } = useTranslation();

	const { classes } = useStyles();

	const descriptionString = !isChildrenProps(props) ? props.description : null;
	const descriptionNode = isChildrenProps(props) ? props.children : null;

	return (
		<Dialog
			open={true}
			disableEscapeKeyDown={true}
			aria-labelledby={props.title}
			aria-describedby={descriptionString || ''}
		>
			{!!props.loading && <LinearProgress />}
			<DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
			<DialogContent>
				{!!descriptionString && (
					<DialogContentText id="alert-dialog-description">{props.description}</DialogContentText>
				)}
				{props.warningMessage && (
					<Typography variant="body1" className={classes.descriptionWarning}>
						{props.warningMessage}
					</Typography>
				)}
				{descriptionNode}
				{!!props.loading && <DialogContentText id="alert-dialog-description">{t('common:loading')}</DialogContentText>}
			</DialogContent>
			<DialogActions>
				{!props.hideCancel && (
					<Button onClick={() => props.onClose(false)} color="secondary" disabled={!!props.loading}>
						{props.cancelButton || t('common:cancel')}
					</Button>
				)}
				<Button onClick={() => props.onClose(true)} color="primary" autoFocus disabled={!!props.loading}>
					{props.confirmButton || t('common:accept')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

function isChildrenProps(data: ConfirmationBasePropsType): data is ChildrenProps {
	return !data.description;
}

const useStyles = makeStyles()((theme) => ({
	descriptionWarning: {
		color: theme.palette.warning.main,
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(1),
	},
}));
