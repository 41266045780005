import { Tooltip } from '@mui/material';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
	title: string;
}>;

function TooltipButton({ title, children }: Props) {
	return (
		<Tooltip title={title}>
			<span>{children}</span>
		</Tooltip>
	);
}

export default TooltipButton;
