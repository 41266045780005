const common = {
	// Common
	username: 'Nombre de usuario',
	password: 'Contraseña',
	firstName: 'Nombre',
	lastName: 'Apellido',
	email: 'Email',
	accept: 'Aceptar',
	cancel: 'Cancelar',
	close: 'Cerrar',
	create: 'Crear',
	delete: 'Eliminar',
	edit: 'Editar',
	filter: 'Filtrar',
	general: 'General',
	reload: 'Recargar',
	save: 'Guardar',
	search: 'Buscar',
	selected: 'seleccionados',
	upload: 'Subir',
	success: 'Se guardó correctamente',
	download: 'Descargar',
	densePadding: 'Fila densa',
	rowsPerPage: 'Líneas por página',
	findUser: 'Busque un usuario ...',
	import: 'Importar',
	importingPleaseWait: 'Por favor espere, importando',
	more: 'Más',
	code: 'Código',
	loading: 'Cargando, por favor espere...',
	role: 'rol',
	role_admin: 'Administrador',
	role_panel: 'Usuario de panel',
	role_user: 'Cliente',
	active: 'Activo',
	inactive: 'Inactivo',
	selectFile: 'Seleccionar archivo',
	fileError: 'Formato de archivo no soportado',
	importSuccess: 'Archivo importado correctamente',
	language: 'Idioma',
	spanish: 'español',
	english: 'inglés',
	valueTo: 'Hasta',
	$lte: 'Menor o igual a',
	$gte: 'Mayor o igual a',
	backOfficeDocumentationTitle: 'Documentación del panel',
	backOfficeDocumentationDescription: 'Link a documentación sobre el uso del panel',
	itDocumentationTitle: 'Documentación IT',
	itDocumentationDescription: 'Link a documentación sobre la configuración del servidor',
	welcome:
		'Bienvenido {{name}}, si necesitás ayuda con el uso del panel, o con alguna configuración del servidor, puedes acceder a la documentación con los links de abajo.',
	export: 'Exportar',
	tokenExpired: 'Tu sesión ha expirado, por favor ingrese nuevamente su usuario y contraseña',
	serverIsDown: 'El servidor se encuentra inactivo, por favor contacte al departamento de sistemas para más ayuda',
	followingElementsWithError:
		'Los siguientes elementos con la columna "{{key}}" ({{elements}}) no pudieron ser creados, error: {{error}}',
	status: 'Estado',
	deleteText: '¿Está seguro que desea eliminar el elemento seleccionado?',
	cancelDescription: '¿Está seguro que desea regresar a la pantalla de login?',
	exampleFile: 'Descargar un archivo .csv de ejemplo',
	account: 'cuenta',
	account_plural: 'cuentas',
	dashboard: 'escritorio',
	favorite: 'favorito',
	favorite_plural: 'favoritos',
	inbox: 'buzón de entrada',
	personal_area: 'Perfil',
	profile: 'perfil',
	user: 'usuario',
	user_plural: 'usuarios',
	user_me: 'mis datos',
	userTitle: 'datos de {{name}}',
	resetPassword: 'Blanquear contraseña',
	resetPasswordTitle: 'Blanquear contraseña',
	resetPasswordText: '¿Desea blanquear la contraseña del usuario seleccionado?',
	creationPasswordText:
		'La primera vez que el usuario inicie sesión creará su contraseña la cual será utilizada en los acesos siguientes.',
	clients_plural: 'Clientes',
	loginDate: 'Último ingreso',
} as const;

export default common;
