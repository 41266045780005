import { IconButton } from '@mui/material';

import TooltipButton from 'components/TooltipButton';

export type CustomTooltipProps = {
	onClick?: () => void | Promise<void>;
	icon: React.ComponentType<{ color?: string }>;
	title: string;
	['aria-label']: string;
	disabled?: boolean;
};

function CustomTooltip({ icon: Icon, disabled, ...props }: CustomTooltipProps) {
	return (
		<TooltipButton title={props.title}>
			<IconButton aria-label={props['aria-label']} onClick={disabled ? undefined : props.onClick} disabled={disabled}>
				<Icon color={disabled ? 'disabled' : undefined} />
			</IconButton>
		</TooltipButton>
	);
}

export default CustomTooltip;
