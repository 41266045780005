import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { redirect } from 'react-router-dom';

import { Formik, FormikErrors } from 'formik';

import { makeStyles } from 'tss-react/mui';
import { Avatar, Box, CircularProgress, CssBaseline, Grid, Paper, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import wallpaper from 'assets/images/main.png';

import { setSession } from 'lib/storage';
import { login, setToken as setTokenApi } from 'lib/api';

import Alert, { AlertsTypes } from 'components/Alert';
import LoginForm from 'components/forms/Login';

import useTokenHasExpired from 'hooks/useTokenHasExpired';
import useIsLogged from 'hooks/useIsLogged';
import useLoggedUser from 'hooks/useLoggedUser';

import useLogin from './hooks/useLogin';
import useIsFirstTime from './hooks/useIsFirstTime';

function Login() {
	const { t } = useTranslation();
	const { classes } = useStyles();

	const tokenExpired = useTokenHasExpired();

	const isFirstTime = useIsFirstTime();
	const logged = useIsLogged();
	const user = useLoggedUser();

	const [requestError, setRequestError] = useState<boolean>(false);
	const [isSubmitting, setSubmitting] = useState<boolean>(false);
	const loginSession = useLogin();

	const initialValues: LoginFormValues = { username: '', password: '' };

	const handleSubmit = async (values: { username: string; password: string }) => {
		setRequestError(false);
		setSubmitting(true);
		const { username, password } = values;
		try {
			const responseAPI = await login({ username, password });

			const response: Session = { ...responseAPI, logged: true, expired: false, serverDown: false };
			setTokenApi(responseAPI.token);

			// Save in local storage
			setSession(response);

			if (!response) {
				setRequestError(true);
				return;
			}

			setSubmitting(false);

			loginSession(response);
		} catch (error) {
			console.error(error);
			setRequestError(true);
			setSubmitting(false);
		}
	};

	const validate = (values: LoginFormValues) => {
		console.warn('values: ', values);
		let errors: FormikErrors<LoginFormValues> = {};
		const { username, password } = values;
		if (username === '') {
			errors.username = t('system:errorsUsername') || 'invalid username';
		}
		if (password === '') {
			errors.password = t('system:errorsPassword') || 'invalid password';
		}
		return errors;
	};

	const loginValidated = !!logged && !!user;

	if (redirect) {
		redirect('/dashboard');
	}

	return loginValidated ? (
		<CircularProgress />
	) : (
		<Grid container component="main" className={classes.root}>
			<CssBaseline />
			<Alert
				show={requestError}
				message={t('system:loginError')}
				type={AlertsTypes.error}
				onClose={() => {
					setRequestError(false);
				}}
			/>
			<Grid item xs={false} sm={4} md={7} className={classes.image} />
			<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
				<Box className={classes.container}>
					<img src={wallpaper} alt="Boostock" width="80%" />
					{!isFirstTime && (
						<Avatar>
							<LockOutlinedIcon />
						</Avatar>
					)}
					<Box className={classes.firstTime}>
						<Typography component="h1" variant="h5">
							{t(!isFirstTime ? 'system:login' : 'system:firstTimeTitle').toUpperCase()}
						</Typography>
						{isFirstTime && (
							<Typography variant="body1" color="textSecondary" component="p" className={classes.text}>
								{t('system:firstTimeDescription')}
							</Typography>
						)}
					</Box>
					<Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
						{(props) => <LoginForm {...props} isSubmitting={isSubmitting} />}
					</Formik>
					{tokenExpired && (
						<Typography variant="caption" className={classes.expired}>
							{t('common:tokenExpired')}
						</Typography>
					)}
				</Box>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles()((theme) => ({
	root: {
		height: '100vh',
		textTransform: 'capitalize',
	},
	firstTime: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	text: {
		textTransform: 'none',
	},
	image: {
		position: 'relative',
		background: 'linear-gradient(#586fa2, #003979)',
	},
	container: {
		margin: theme.spacing(8, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	expired: {
		color: 'red',
		marginTop: theme.spacing(1),
		textTransform: 'none',
	},
}));

export default Login;
