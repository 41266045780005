import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Alert, { AlertsTypes } from 'components/Alert';

import { makeStyles } from 'tss-react/mui';
import { Button, FormGroup, LinearProgress, TextField, Select, MenuItem, Box } from '@mui/material';
import { Save as SaveIcon, Delete as DeleteIcon, Close as CloseIcon } from '@mui/icons-material';

import { fetchUsers, updateUser, deleteUser, resetPassword } from 'lib/models/users';

import useLoggedUserDocument from 'hooks/useLoggedUserDocument';
import ConfirmationDialog from 'components/ConfirmationDialog';

import useLogout from 'hooks/useLogout';
import useSelectedLanguage from 'hooks/useSelectedLanguage';

import useUpdateLanguage from './hooks/useUpdateLanguage';
import useUserId from '../../hooks/useUserId';

type Props = {
	id: string;
	editingSelf: boolean;
	useDisableEdit: boolean;
	withPasswordReset: boolean;
	isModal: boolean;
	withDelete: boolean;
	type?: UserType[];
	withStatus: boolean;
};

function UserEditionForm({
	id,
	withDelete,
	editingSelf,
	useDisableEdit,
	withPasswordReset,
	isModal,
	type,
	withStatus,
}: Props) {
	const { t } = useTranslation();

	const updateLanguage = useUpdateLanguage();

	const navigate = useNavigate();

	const { classes } = useStyles();

	const newUser = !!id && id === 'new';

	const selectedLanguage = useSelectedLanguage();

	const logout = useLogout();

	const [loading, setLoading] = useState<boolean>(true);
	const [user, setUser] = useState<User>({
		_id: '',
		first_name: '',
		last_name: '',
		Rules: {},
		status: 'isactive',
		type: !!type ? type[0] : 'user',
		username: '',
	});

	const [error, setError] = useState<string>('');
	const [success, setSuccess] = useState<boolean>(false);
	const [disableEdit, setDisableEdit] = useState<boolean>(useDisableEdit ? true : false);
	const [confirmDeleteUser, setDeleteUser] = useState(false);
	const [confirmResetPassword, setResetPassword] = useState(false);
	const [language, setLanguage] = useState(selectedLanguage);

	const userSession = useLoggedUserDocument();

	const { id: loggedUserId } = useUserId();
	const loggedUserType: UserType | '' = userSession?.type || '';
	const isAdmin = loggedUserType === 'admin';

	const canSave = user.email && user.first_name && user.last_name;

	useEffect(() => {
		setLanguage(selectedLanguage);
	}, [selectedLanguage]);

	useEffect(() => {
		const init = async () => {
			if (id && id === 'new') {
				if (isAdmin) {
					setDisableEdit(false);
				}
				setLoading(false);
				return;
			}

			const response = await fetchUsers();

			const found = id || loggedUserId ? response.find((user) => user._id === (id || loggedUserId)) : null;

			if (found) {
				setUser(found);
				if (found._id === loggedUserId) {
					setDisableEdit(false);
				}
			} else if (!id) {
				setUser(userSession!);
				setDisableEdit(false);
			} else if (isAdmin && useDisableEdit) {
				setDisableEdit(true);
			}

			setLoading(false);
		};

		init();
	}, [id, isAdmin, loggedUserId, loggedUserType, t, useDisableEdit, userSession]);

	const onUpdate = (key: keyof User) => {
		return (event: any) => {
			const value = event.target.value;

			if (user) {
				setUser({ ...user, [key]: value });
			}
		};
	};

	const save = async () => {
		try {
			setLoading(true);
			await updateUser(user);
			setSuccess(true);
			if (language) updateLanguage(language);
		} catch (error) {
			setError(error && (error as any).message ? (error as any).message : error);
		}
		setLoading(false);
		navigate('/users');
	};

	function toggleDeleteUser() {
		setDeleteUser(true);
	}

	function toggleResetPassword() {
		setResetPassword(true);
	}

	async function onDeleteConfirm(confirmed: boolean) {
		try {
			setDeleteUser(false);
			if (confirmed) {
				setLoading(true);
				await deleteUser(user);
				navigate('/users');
			}
		} catch (error) {
			setError(error && (error as any).message ? (error as any).message : error);
		}
		setLoading(false);
	}

	async function onResetConfirm(confirmed: boolean) {
		try {
			setResetPassword(false);
			if (confirmed) {
				setLoading(true);
				await resetPassword(user);
				if (!id) {
					logout();
				} else {
					navigate(isAdmin ? '/users' : '/');
				}
			}
		} catch (error) {
			setError(error && (error as any).message ? (error as any).message : error);
		}
		setLoading(false);
	}

	const userCreated = !loading && success && newUser;

	return (
		<Box>
			{isModal && (
				<Box className={classes.closeButtonContainer}>
					<Button
						variant="contained"
						color="secondary"
						size="large"
						className={classes.closeButton}
						startIcon={<CloseIcon />}
						onClick={() => navigate('/users')}
					>
						{t('common:close')}
					</Button>
				</Box>
			)}

			{loading && <LinearProgress className={classes.input} />}

			<FormGroup>
				<TextField
					id="username"
					label={t('common:username')}
					fullWidth={true}
					value={user.username}
					variant="outlined"
					className={classes.input}
					onChange={onUpdate('username')}
					disabled={id !== 'new'}
				/>

				<TextField
					id="first"
					label={t('common:firstName')}
					fullWidth={true}
					value={user.first_name}
					variant="outlined"
					className={classes.input}
					onChange={onUpdate('first_name')}
					disabled={loading || disableEdit}
				/>
				<TextField
					id="last"
					label={t('common:lastName')}
					fullWidth={true}
					value={user.last_name}
					variant="outlined"
					className={classes.input}
					onChange={onUpdate('last_name')}
					disabled={loading || disableEdit}
				/>
				<TextField
					id="email"
					label={t('common:email')}
					fullWidth={true}
					value={user.email || ''}
					variant="outlined"
					className={classes.input}
					onChange={onUpdate('email')}
					inputMode="email"
					type="email"
					disabled={loading || disableEdit}
				/>
				{isAdmin && !type && (
					<Select
						labelId="type"
						id="type"
						label={t('common:role')}
						fullWidth={true}
						value={user.type || 'user'}
						variant="outlined"
						className={classes.input}
						onChange={onUpdate('type')}
						disabled={loading || disableEdit}
					>
						<MenuItem value="admin">{t('common:role_admin')}</MenuItem>
						<MenuItem value="panel">{t('common:role_panel')}</MenuItem>
					</Select>
				)}
				{!!type && (
					<Select
						labelId="type"
						id="type"
						label={t('common:role')}
						fullWidth={true}
						value={user.type || 'user'}
						variant="outlined"
						className={classes.input}
						onChange={onUpdate('type')}
						disabled={loading || disableEdit}
					>
						{type.map((el) => {
							return (
								<MenuItem key={`type-${el}`} value={el}>
									{t(`common:role_${el}`)}
								</MenuItem>
							);
						})}
					</Select>
				)}
				{isAdmin && withStatus && (
					<Select
						labelId="status"
						id="status"
						label={t('common:status')}
						fullWidth={true}
						value={user.status || 'isactive'}
						variant="outlined"
						className={classes.input}
						onChange={onUpdate('status')}
						disabled={loading || disableEdit}
					>
						<MenuItem value="isactive">{t('common:active')}</MenuItem>
						<MenuItem value="inactive">{t('common:inactive')}</MenuItem>
					</Select>
				)}
				{!disableEdit && !newUser && editingSelf && (
					<Select
						labelId="language"
						id="language"
						label={t('common:language')}
						fullWidth={true}
						value={language || 'es'}
						variant="outlined"
						className={classes.input}
						onChange={(ev) => setLanguage(ev.target.value as SupportedLanguage)}
						disabled={loading}
					>
						<MenuItem value="es">{t('common:spanish')}</MenuItem>
						<MenuItem value="en">{t('common:english')}</MenuItem>
					</Select>
				)}

				{!disableEdit && (
					<Button
						variant="contained"
						color="primary"
						size="large"
						className={classes.button}
						startIcon={<SaveIcon />}
						onClick={save}
						disabled={!canSave}
					>
						{t('common:save')}
					</Button>
				)}
				{withDelete && !disableEdit && user.username !== 'admin' && isAdmin && !newUser && !editingSelf && (
					<Button
						variant="contained"
						color="secondary"
						size="large"
						className={classes.button}
						startIcon={<DeleteIcon />}
						onClick={toggleDeleteUser}
					>
						{t('common:delete')}
					</Button>
				)}
				{withPasswordReset && !disableEdit && !newUser && (
					<Button
						variant="contained"
						color="warning"
						size="large"
						className={classes.button}
						startIcon={<DeleteIcon />}
						onClick={toggleResetPassword}
					>
						{t('common:resetPassword')}
					</Button>
				)}
			</FormGroup>

			{!userCreated && confirmDeleteUser && (
				<ConfirmationDialog
					title={t('users:deleteTitle')}
					description={t('common:deleteText') || ''}
					onClose={onDeleteConfirm}
					loading={loading}
				/>
			)}

			{!userCreated && confirmResetPassword && (
				<ConfirmationDialog
					title={t('common:resetPasswordTitle')}
					description={t('common:resetPasswordText') || ''}
					onClose={onResetConfirm}
					loading={loading}
				/>
			)}

			{!loading && success && (
				<Alert
					message={t('common:success')}
					show={success}
					type={AlertsTypes.success}
					onClose={() => !userCreated && setSuccess(false)}
				/>
			)}
			{!loading && error && (
				<Alert message={error} show={!!error} type={AlertsTypes.error} onClose={() => setError('')} />
			)}
		</Box>
	);
}

const useStyles = makeStyles()((theme) => ({
	input: {
		marginBottom: theme.spacing(3),
	},
	large: {
		width: theme.spacing(10),
		height: theme.spacing(10),
		margin: theme.spacing(4),
	},
	button: {
		margin: theme.spacing(1),
	},
	closeButtonContainer: {
		display: 'flex',
		flexDirection: 'row',
		marginBottom: theme.spacing(1),
		justifyContent: 'flex-end',
	},
	closeButton: {},
	text: {
		marginTop: theme.spacing(2),
	},
	subTree: {
		display: 'inline-block',
	},
}));

export default UserEditionForm;
