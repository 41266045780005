import { useCallback, useEffect, useState } from 'react';

import { fetchUsers } from 'lib/models/users';

function useUsers(type?: UserType) {
	const [loading, setLoading] = useState<boolean>(true);
	const [users, setUsers] = useState<User[]>([]);

	const refetch = useCallback(async () => {
		const response = await fetchUsers(type);
		setUsers(response);
		setLoading(false);
	}, [type]);

	useEffect(() => {
		refetch();
	}, [refetch]);

	const usersIds = users.map(({ _id }) => _id).join(',');

	const addUsers = useCallback(
		(data: User[]) => {
			setUsers((currentUsers) => {
				return [
					// Update existing users
					...currentUsers.map((current) => {
						const found = data.find((el) => el._id === current._id);
						return found || current;
					}),
					// Add new users
					...data.filter((user) => user._id && !usersIds.includes(user._id)),
				];
			});
			setLoading(false);
		},
		[usersIds]
	);

	return { loading, users, refetch, addUsers, setLoading };
}

export default useUsers;
