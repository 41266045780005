import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { LinearProgress } from '@mui/material';

import Alert, { AlertsTypes } from 'components/Alert';

import ScreenContainer from 'components/containers/ScreenContainer';
import CreateIcon from 'components/icons/CreateIcon';
import useUsers from 'hooks/useUsers';
import UserTable from 'components/UserTable';

function Users() {
	const { t } = useTranslation();

	const { loading, users } = useUsers();

	const [error, setError] = useState<string>('');

	const navigate = useNavigate();

	return (
		<ScreenContainer title={t('common:user_plural')}>
			<CreateIcon onCreate={() => navigate('/users/new')} />
			<Alert message={error} show={!!error} type={AlertsTypes.error} onClose={() => setError('')} />

			{!!loading && <LinearProgress />}

			<UserTable users={users} loading={loading} />
		</ScreenContainer>
	);
}

export default Users;
