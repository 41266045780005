import { useTranslation } from 'react-i18next';

import { IconButton } from '@mui/material';
import { Replay as ReloadIcon } from '@mui/icons-material';

import TooltipButton from 'components/TooltipButton';

const DeleteTooltip = ({ onClick }: { onClick?: () => void | Promise<void> }) => {
	const { t } = useTranslation();

	return (
		<TooltipButton title={t('common:reload') as string}>
			<IconButton aria-label={t('common:reload') || ''} onClick={onClick}>
				<ReloadIcon />
			</IconButton>
		</TooltipButton>
	);
};

export default DeleteTooltip;
