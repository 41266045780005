import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import ScreenContainer from 'components/containers/ScreenContainer';

function Inbox() {
	const { t } = useTranslation();
	return (
		<ScreenContainer title={t('common:inbox')}>
			<Typography>{t('common:inbox')}</Typography>
		</ScreenContainer>
	);
}

export default Inbox;
