import { useTranslation } from 'react-i18next';

import { MenuItem, MenuItemProps, ListItemIcon, ListItemText } from '@mui/material';

import { ExitToApp as ExitToAppIcon } from '@mui/icons-material';

// type LogoutProps = MenuItemProps;

export default function Logout({ onClick }: MenuItemProps) {
	const { t } = useTranslation();

	return (
		<MenuItem onClick={onClick}>
			<ListItemIcon>
				<ExitToAppIcon />
			</ListItemIcon>
			<ListItemText primary={t('system:logout')} />
		</MenuItem>
	);
}
