import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';

type IconCardProps = {
	title: string;
	description: string;
	icon: React.ReactElement;
	primaryAction?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
};

function IconCard({ title, description, icon, primaryAction }: IconCardProps) {
	const { classes } = useStyles();

	return (
		<Card className={classes.root}>
			<CardMedia className={classes.media}>{icon}</CardMedia>
			<CardActionArea onClick={primaryAction}>
				<CardContent>
					<Typography gutterBottom variant="h5" component="h2">
						{title}
					</Typography>
					<Typography variant="body2" color="textSecondary" component="p">
						{description}
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}

export default IconCard;

const useStyles = makeStyles()((theme) => ({
	root: {
		width: 345,
		margin: theme.spacing(2),
	},
	media: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: theme.palette.primary.main,
		padding: theme.spacing(4),
	},
}));
