import { PropsWithChildren, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Box, LinearProgress } from '@mui/material';
import PaperContainer from 'components/containers/PaperContainer';

type Props = {
	className?: string;
	loading?: boolean;
	paper?: boolean;
};

function FullLayout({ children, className, loading = false, paper = true }: PropsWithChildren<Props>) {
	const { classes } = useStyles();

	const Component = useMemo(() => (paper ? PaperContainer : Box), [paper]);

	return (
		<Component className={className || classes.root}>
			{loading && <LinearProgress />}
			{children}
		</Component>
	);
}

export default FullLayout;

const useStyles = makeStyles()((theme) => ({
	root: {
		padding: theme.spacing(2),
		height: 'calc(100vh - 180px)', // 180px header height
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
	},
}));
