import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { CircularProgress, Typography } from '@mui/material';

import PaperContainer from 'components/containers/PaperContainer';

function LoadingLayout() {
	const { classes } = useStyles();
	const { t } = useTranslation();
	return (
		<PaperContainer className={classes.root}>
			<CircularProgress />
			<Typography variant="caption" display="block" gutterBottom className={classes.text}>
				{t('common:loading')}
			</Typography>
		</PaperContainer>
	);
}

export default LoadingLayout;

const useStyles = makeStyles()((theme) => ({
	root: {
		height: '100vh',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	text: {
		marginTop: theme.spacing(1),
	},
}));
