import { useTranslation } from 'react-i18next';

import { LinearProgress } from '@mui/material';

import ScreenContainer from 'components/containers/ScreenContainer';
import useUsers from 'hooks/useUsers';
import ClientTable from 'components/ClientTable';
import { UsersTypes } from 'types/global';

function Clients() {
	const { t } = useTranslation();

	const { loading, users } = useUsers(UsersTypes.user);

	return (
		<ScreenContainer title={t('common:clients_plural')}>
			{!!loading && <LinearProgress />}

			<ClientTable users={users} loading={loading} />
		</ScreenContainer>
	);
}

export default Clients;
