import { useCallback } from 'react';

import { useParams, Outlet } from 'react-router-dom';
import { validateComponent } from 'lib/session';
import { getDataFromString, isServerRules } from 'lib/helpers';

import useLoggedAndRules from 'hooks/useLoggedWithRules';

import Login from 'components/screens/system/Login';
import { Unauthorized } from 'components/screens/system';

function Auth({ element: Element, mode, ...rest }: RoutesProps) {
	const { logged, rulesString } = useLoggedAndRules();

	const validRules = useCallback(() => isServerRules(rest.path), [rest.path]);

	const rules = getDataFromString<ServerRules>(rulesString, validRules());

	const params = useParams();

	const validated = validateComponent(rules, { ...params, ...rest }, mode);

	if (!logged) {
		return <Login />;
	}

	if (!validated) {
		return <Unauthorized />;
	}

	return Element ? <Element /> : <Outlet />;
}

export default Auth;

// function Auth({ element: Element, mode, ...rest }: RoutesProps) {
// 	const { logged, rulesString } = useLoggedAndRules();

// 	const validRules = useCallback(() => isServerRules(rest.path), [rest.path]);

// 	const rules = getDataFromString<ServerRules>(rulesString, validRules());

// 	const params = useParams();

// 	const validated = validateComponent(rules, { ...params, ...rest }, mode);

// 	return (
// 		<Route>
// 			{!logged && <Login />}

// 			{logged && !validated && <Unauthorized />}

// 			{logged && validated && !Element && <Outlet />}

// 			{logged && validated && Element && <Element />}
// 		</Route>
// 	);
// }
