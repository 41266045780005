import { useTranslation } from 'react-i18next';

import { Box, IconButton } from '@mui/material';
import { CloudDownload as ExportIcon } from '@mui/icons-material';

import TooltipButton from 'components/TooltipButton';

export type ExportTooltipProps = {
	onExport: () => any;
};

function ExportTooltip({ onExport }: ExportTooltipProps) {
	const { t } = useTranslation();

	return (
		<Box>
			<TooltipButton title={t('common:export') as string}>
				<IconButton aria-label={t('common:export') || ''} onClick={onExport}>
					<ExportIcon />
				</IconButton>
			</TooltipButton>
		</Box>
	);
}

export default ExportTooltip;
