import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
//@WARNING: if you want to use web-vitals, please uncomment code in file!
// import reportWebVitals from './reportWebVitals';

//@ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
