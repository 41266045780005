import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { Box, Table as MUITable, TableBody, TablePagination } from '@mui/material';

export type TableProps<T> = {
	renderHead: () => React.ReactElement;
	renderRow: (element: T) => React.ReactElement;
	pagination?: {
		rowsPerPageOptions: number[];
		count: number;
		rowsPerPage: number;
		page: number;
		onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
		onRowsPerPageChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
	} | null;
	containerClass?: string;
};

type Props<T> = TableProps<T> & {
	elements: T[];
	containerClass?: string;
};

/**
 * Creates a material ui list or table depending on the media query
 *
 * @param elements {array} Array of elements to be listed
 * @param loading {boolean} [Optional defaults to false] Boolean indicating that the data is loading
 * @param list {object} [Optional] Configuration needed to render the data as List (at least one of "list" or "table" properties is needed)
 * @param table {object} [Optional] Configuration needed to render the data as Table (at least one of "list" or "table" properties is needed)
 * @param breakpoint {Breakpoint} [Optional defaults to "md"] The media query breakpoint to be used (if exists both, "list" and "table" must exists)
 */
function Table<T>({ elements, renderHead, renderRow, pagination, containerClass }: Props<T>) {
	const { t } = useTranslation();

	const { classes } = useStyles();

	return (
		<Box className={classes.fullHeight}>
			<Box className={`${classes.container} ${containerClass || ''}`}>
				<MUITable size="medium">
					{renderHead()}
					<TableBody>{elements.map((element) => renderRow(element))}</TableBody>
				</MUITable>
			</Box>
			{!!pagination && <TablePagination component="div" labelRowsPerPage={t('common:rowsPerPage')} {...pagination} />}
		</Box>
	);
}

const useStyles = makeStyles()((theme) => ({
	fullHeight: {
		overflow: 'auto',
		height: '100%',
	},
	container: {
		height: '90%',
		overflow: 'auto',
		// maxWidth: '1000px',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
}));

export default Table;
