import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { Box } from '@mui/material';
import { FindInPage as DocIcon } from '@mui/icons-material';

import ScreenContainer from 'components/containers/ScreenContainer';
import IconCard from 'components/IconCard';
import useLoggedUser from 'hooks/useLoggedUser';
import { PANEL_MANUAL_URL, IT_MANUAL_URL } from 'config/constants';

function Dashboard() {
	const { t } = useTranslation();

	const { classes } = useStyles();

	const user = useLoggedUser();

	return (
		<ScreenContainer title={t('common:dashboard')} className={classes.root}>
			<Box>
				<p>{t('common:welcome', { name: user?.first_name || '' })}</p>
			</Box>
			<Box className={classes.cards}>
				<a href={PANEL_MANUAL_URL} target="_blank" rel="noreferrer" className={classes.link}>
					<IconCard
						title={t('common:backOfficeDocumentationTitle')}
						description={t('common:backOfficeDocumentationDescription')}
						icon={<DocIcon />}
					/>
				</a>
				<a href={IT_MANUAL_URL} target="_blank" rel="noreferrer" className={classes.link}>
					<IconCard
						title={t('common:itDocumentationTitle')}
						description={t('common:itDocumentationDescription')}
						icon={<DocIcon />}
					/>
				</a>
			</Box>
		</ScreenContainer>
	);
}

export default Dashboard;

const useStyles = makeStyles()((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	cards: {
		display: 'flex',
	},
	link: {
		textDecoration: 'none',
	},
}));
