import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Alert, { AlertsTypes } from 'components/Alert';

import { makeStyles } from 'tss-react/mui';
import { Button, FormGroup, LinearProgress, TextField, Select, MenuItem, Box } from '@mui/material';
import { Save as SaveIcon, Delete as DeleteIcon } from '@mui/icons-material';

import { fetchUsers, updateUser, deleteUser } from 'lib/models/users';

import ConfirmationDialog from 'components/ConfirmationDialog';

import { UsersTypes } from 'types/global';

type Props = {
	id: string;
};

function ClientEditionForm({ id }: Props) {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const { classes } = useStyles();

	const [loading, setLoading] = useState<boolean>(true);
	const [user, setUser] = useState<User>({
		_id: '',
		first_name: '',
		last_name: '',
		Rules: {},
		status: 'isactive',
		type: UsersTypes.user,
		username: '',
	});

	const [error, setError] = useState<string>('');
	const [success, setSuccess] = useState<boolean>(false);

	const [confirmDeleteUser, setDeleteUser] = useState(false);

	const canSave = !!user.email;

	useEffect(() => {
		const init = async () => {
			const response = await fetchUsers(UsersTypes.user);

			const found = id ? response.find((user) => user._id === id) : null;

			if (found) {
				setUser(found);
			} else {
				navigate('/clients');
			}

			setLoading(false);
		};

		init();
	}, [id, navigate]);

	const onUpdate = (key: keyof User) => {
		return (event: any) => {
			const value = event.target.value;

			if (user) {
				setUser({ ...user, [key]: value });
			}
		};
	};

	const save = async () => {
		try {
			setLoading(true);
			await updateUser(user);
			setSuccess(true);
		} catch (error) {
			setError(error && (error as any).message ? (error as any).message : error);
		}
		setLoading(false);
		navigate('/clients');
	};

	function toggleDeleteUser() {
		setDeleteUser(true);
	}

	async function onDeleteConfirm(confirmed: boolean) {
		try {
			setDeleteUser(false);
			if (confirmed) {
				setLoading(true);
				await deleteUser(user);
				navigate('/clients');
			}
		} catch (error) {
			setError(error && (error as any).message ? (error as any).message : error);
		}
		setLoading(false);
	}

	return (
		<Box>
			{loading && <LinearProgress className={classes.input} />}

			<FormGroup>
				<TextField
					id="first"
					label={t('common:firstName')}
					fullWidth={true}
					value={user.first_name}
					variant="outlined"
					className={classes.input}
					onChange={onUpdate('first_name')}
					disabled={true}
				/>
				<TextField
					id="last"
					label={t('common:lastName')}
					fullWidth={true}
					value={user.last_name}
					variant="outlined"
					className={classes.input}
					onChange={onUpdate('last_name')}
					disabled={true}
				/>
				<TextField
					id="email"
					label={t('common:email')}
					fullWidth={true}
					value={user.email || ''}
					variant="outlined"
					className={classes.input}
					onChange={onUpdate('email')}
					inputMode="email"
					type="email"
					disabled={true}
				/>

				<Select
					labelId="status"
					id="status"
					label={t('common:status')}
					fullWidth={true}
					value={user.status || 'isactive'}
					variant="outlined"
					className={classes.input}
					onChange={onUpdate('status')}
					disabled={loading}
				>
					<MenuItem value="isactive">{t('common:active')}</MenuItem>
					<MenuItem value="inactive">{t('common:inactive')}</MenuItem>
				</Select>

				<Button
					variant="contained"
					color="primary"
					size="large"
					className={classes.button}
					startIcon={<SaveIcon />}
					onClick={save}
					disabled={!canSave}
				>
					{t('common:save')}
				</Button>

				<Button
					variant="contained"
					color="secondary"
					size="large"
					className={classes.button}
					startIcon={<DeleteIcon />}
					onClick={toggleDeleteUser}
				>
					{t('common:delete')}
				</Button>
			</FormGroup>

			{confirmDeleteUser && (
				<ConfirmationDialog
					title={t('users:deleteTitle')}
					description={t('common:deleteText') || ''}
					onClose={onDeleteConfirm}
					loading={loading}
				/>
			)}

			{!loading && success && (
				<Alert
					message={t('common:success')}
					show={success}
					type={AlertsTypes.success}
					onClose={() => setSuccess(false)}
				/>
			)}
			{!loading && error && (
				<Alert message={error} show={!!error} type={AlertsTypes.error} onClose={() => setError('')} />
			)}
		</Box>
	);
}

const useStyles = makeStyles()((theme) => ({
	input: {
		marginBottom: theme.spacing(3),
	},
	large: {
		width: theme.spacing(10),
		height: theme.spacing(10),
		margin: theme.spacing(4),
	},
	button: {
		margin: theme.spacing(1),
	},
	closeButtonContainer: {
		display: 'flex',
		flexDirection: 'row',
		marginBottom: theme.spacing(1),
		justifyContent: 'flex-end',
	},
	closeButton: {},
	text: {
		marginTop: theme.spacing(2),
	},
	subTree: {
		display: 'inline-block',
	},
}));

export default ClientEditionForm;
