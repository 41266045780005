const system = {
	// System
	errorsUsername: 'fill username',
	errorsPassword: 'fill password',
	login: 'login',
	loginError: 'username or password error',
	logout: 'logout',
	logoutModalText: 'close session?',
	nextPage: 'next page',
	previousPage: 'previous page',
	profile: 'profile',
	print: 'print',
	preview: 'preview',
	rememberUser: 'remember username?',
	rowsPerPage: 'rows per page',
	requestError: 'there was a comunication error, try again.',
	requestFinalError: 'system out of service, try again later.',
	sortAscending: 'sorted ascending',
	sortDescending: 'sorted descending',
	titlesNoMatch: 'url not found',
	titlesUnauthorized: 'unauthorized access',
	firstTimeTitle: 'Password generation',
	firstTimeDescription:
		'Before starting, it is necessary to assign a password to the administrator user, for that, login using the username "admin" and a password of your choice.',
	forgot: 'Forgot my password',
	recoveryMessage: 'Enter your username and your new password',
	recoveryCodeMessage: 'Enter the code you received by email',
	recoveryErrorMessage: 'There was an error trying to recover the password for this account',
} as const;

export default system;
