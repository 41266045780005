import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import {
	Box,
	useTheme,
	AppBar,
	Typography,
	useMediaQuery,
	IconButton,
	Toolbar as MaterialToolbar,
} from '@mui/material';

import { AccountCircle as AccountIcon, Menu as MenuIcon } from '@mui/icons-material';

import { DRAWER_WIDTH } from 'config/constants';

import ProfileMenu from './ProfileMenu';

type ToolbarProps = {
	open: boolean;
	onOpen(): void;
	title: string;
};

function Toolbar({ open, onOpen, title }: ToolbarProps) {
	const { classes } = useStyles();
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const sessionMenuOpen = Boolean(anchorEl);
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<AppBar
			position={isMobile ? 'fixed' : 'fixed'}
			color="default"
			className={clsx(classes.appBar, {
				[classes.appBarShift]: open && !isMobile,
			})}
			elevation={3}
		>
			<MaterialToolbar className={classes.toolbar}>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					onClick={onOpen}
					edge="start"
					className={clsx(classes.menuButton, {
						[classes.hide]: open && !isMobile,
					})}
				>
					<MenuIcon />
				</IconButton>
				<Typography variant="h6" className={clsx(classes.title, { [classes.titleShift]: open && !isMobile })} noWrap>
					{title}
				</Typography>
				<Box className={classes.grow} />
				<IconButton onClick={handleClick}>
					<AccountIcon />
				</IconButton>
				<ProfileMenu open={sessionMenuOpen} anchor={anchorEl} onClose={handleClose} />
			</MaterialToolbar>
		</AppBar>
	);
}

const useStyles = makeStyles()((theme) => ({
	appBar: {
		width: '100%',
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		textTransform: 'capitalize',
		zIndex: theme.zIndex.drawer + 1,
	},
	appBarShift: {
		width: `calc(100% - ${DRAWER_WIDTH}px)`,
		marginLeft: DRAWER_WIDTH,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	toolbar: {
		height: 128,
		alignItems: 'flex-start',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
		alignSelf: 'flex-end',
		paddingLeft: theme.spacing(4),
		paddingBottom: theme.spacing(2),
	},
	titleShift: {
		paddingLeft: theme.spacing(4),
	},
	hide: {
		display: 'none',
	},
	grow: {
		flexGrow: 1,
	},
}));

export default Toolbar;
